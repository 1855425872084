let deferredPrompt;
const installBanner = document.getElementById('installBanner');
const installButton = document.getElementById('installButton');
const dismissButton = document.getElementById('dismissButton');
const PWA_PROMPT_LAST_SHOWN = 'pwaPromptLastShown';
const PWA_INSTALLED = 'isPwaInstalled';
const DAYS_BEFORE_REPROMPT = 90;

function shouldShowPrompt() {
    const lastShown = localStorage.getItem(PWA_PROMPT_LAST_SHOWN);
    const isPwaInstalled = localStorage.getItem(PWA_INSTALLED);
    const currentTime = new Date().getTime();
    const ninetyDaysInMillis = DAYS_BEFORE_REPROMPT * 24 * 60 * 60 * 1000;

    if (isPwaInstalled === 'true') {
        return false;
    }

    if (!lastShown || (currentTime - lastShown) > ninetyDaysInMillis) {
        return true;
    }

    return false;
}

window.addEventListener('beforeinstallprompt', (e) => {
    // Prevent the default mini-infobar from appearing on mobile
    e.preventDefault();
    // Save the event for later use
    deferredPrompt = e;

    // Show the installation banner if it's time to show the prompt
    if (shouldShowPrompt()) {
        if (installBanner) {
            installBanner.classList.remove('d-none');
        }
    }
});

if (installButton) {
    installButton.addEventListener('click', () => {
        if (deferredPrompt) {
            // Show the install prompt
            deferredPrompt.prompt();

            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                    localStorage.setItem(PWA_INSTALLED, 'true');
                } else {
                    console.log('User dismissed the install prompt');
                    localStorage.setItem(PWA_INSTALLED, 'false');
                }
                localStorage.setItem(PWA_PROMPT_LAST_SHOWN, new Date().getTime());

                // Hide the banner
                if (installBanner) {
                    installBanner.classList.add('d-none');
                }

                deferredPrompt = null;
            });
        } else {
            alert('PWA installation is not supported or already installed on this device.');
        }
    });
}

if (dismissButton) {
    dismissButton.addEventListener('click', () => {
        localStorage.setItem(PWA_PROMPT_LAST_SHOWN, new Date().getTime());
        if (installBanner) {
            installBanner.classList.add('d-none');
        }
    });
}

document.addEventListener('DOMContentLoaded', () => {
    if (!shouldShowPrompt()) {
        if (installBanner) {
            installBanner.classList.add('d-none');
        }
    }
});
